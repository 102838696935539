@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.autocompleteList {
	@include list-unstyled();
	margin-top: -0.625rem;
	margin-bottom: 0;
}
.autocompleteListItem {
	margin-top: 0.75rem;
}
.autocompleteListButton {
	display: flex;
	align-items: center;
	width: 100%;
	color: $grey-dark;
	text-align: left;
	text-decoration: none;

	// HOVERs
	&:hover {
		color: $primary;
		text-decoration: underline;
		.autocompleteListHighlighted {
			color: $primary;
		}
	}
}
.autocompleteListButtonIcon {
	margin-right: 0.75rem;
	padding: 0.4375rem;
	border: 1px solid $grey-medium;
	border-radius: $border-radius-sm;
	color: $grey-dark;
}

.autocompleteListContent {
    display: flex;
    flex-flow: column nowrap;
    margin-top: -0.5rem;
}

.autocompleteListType {
    display: inline-block;
    font-size: 0.7rem;
    line-height: 1;

    &:first-letter {
        text-transform: uppercase;
    }
}

.autocompleteListHighlighted {
	color: $anthracite-grey;
	transition: color $transition;
}
