@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.autocompleteBox {
    position: relative;
    width: 100%;
    border: 1px solid $grey-light;
    z-index: $zindex-dropdown;
    border-top: 0;
    max-height: 30rem;
    overflow: auto;

    &--overlay {
        position: absolute;
    }

    &--homepage {
        position: absolute;
        top: 1px;
        width: 500px;
    }
}

.autocompleteBoxFlat {
    li {
        padding: 0.1rem 0.5rem;
        cursor: pointer;
        transition: background-color $transition;

        &:last-child {
            border-bottom: 0;
        }
    }

    li:hover {
        color: #c23427;
        background-color: $grey-light;
    }
}

.autocompleteMap {
    position: absolute;
    top: 0;
    height: 200px;
    width: 100%;

    @media ($mdUp) {
        height: 300px;
    }

    @media ($lgUp) {
        height: 400px;
    }

    > span {
        margin-top: 0.5rem;
        display: inline-flex;
        color: $grey-dark;
        font-size: 0.6rem;
        align-items: center;
        gap: 0.25rem;
    }
}

.autocomplete {
    &--mapHolder {
        min-height: 200px;

        @media ($mdUp) {
            min-height: 300px;
        }

        @media ($lgUp) {
            min-height: 400px;
        }
    }
}
